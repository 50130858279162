import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

// function ageCalculation (dateofBirth) {
//   const today = new Date()
//   const birthDate = new Date(dateofBirth)
//   let age = today.getFullYear() - birthDate.getFullYear()
//   const m = today.getMonth() - birthDate.getMonth()
//   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//       age--
//   }
//   return age
// }
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm, allData, searchDAta, dateList) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: vm.getOfficeName(allData[0].office_id), style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
              body: [
                [
                  { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: searchDAta.circular_memo_no, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? searchDAta.fiscal_year_bn : searchDAta.fiscal_year, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? searchDAta.org_bn : searchDAta.org, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? searchDAta.ofc_type_name_bn : searchDAta.ofc_type_name, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? searchDAta.ofc_name_bn : searchDAta.ofc_name, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? searchDAta.training_type_bn : searchDAta.training_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? searchDAta.training_category_bn : searchDAta.training_category, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? searchDAta.training_title_bn : searchDAta.training_title, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_iabm.training_start_date'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: dateFormat(vm.search.training_start_date), style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_iabm.training_end_date'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: dateFormat(vm.search.training_end_date), style: 'td', alignment: 'left' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: '', style: 'hh2', alignment: 'center' })
        const firstHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tpm.trainee_name'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('admission_form.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tpm.father_name'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_accommodation.address'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('admission_form.age'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('admission_form.monthly_income'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tpm.edu_quali'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tpm.sig_date'), style: 'th', colSpan: dateList.length, alignment: 'center', bold: true }
        ]
        if (dateList.length > 1) {
          for (let i = 1; i < dateList.length; i++) {
            firstHeader.push(
              {}
            )
          }
        }
        const allRows = [
          firstHeader
        ]
        const secondHeader =
          [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {}
          ]
          dateList.forEach(dateItem => {
            secondHeader.push(
              { text: (dateItem) ? dateFormat(dateItem.text) : '', style: 'th', alignment: 'center', bold: true }
            )
          })
        allRows.push(secondHeader)
        allData.forEach((info, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.name_bn : info.name, alignment: 'center', style: 'td' },
            { text: vm.$n('0') + vm.$n(info.mobile, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.name_of_father_husband_bn : info.name_of_father_husband, alignment: 'center', style: 'td' },
            { text: info.other_address, alignment: 'center', style: 'td' },
            { text: vm.$n(info.age), alignment: 'center', style: 'td' },
            { text: vm.$n(info.monthly_income), alignment: 'center', style: 'td' },
            { text: info.name_of_highest_degree, alignment: 'center', style: 'td' }
          ]
          dateList.forEach(dateItem => {
            rowItem.push(
              { text: '', alignment: 'center', style: 'td' }
            )
          })
          allRows.push(rowItem)
        })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: '*',
            body: allRows
          }
        })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 11 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 11 : 9,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 9,
              bold: true,
              margin: [5, -25, 5, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          hh2: {
              fontSize: 13,
              bold: true,
              margin: [10, 5, 25, 20]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          krishi: {
            fontSize: 15,
            margin: [0, 1, 0, 25],
            alignment: 'center'
          },
          tableSubHead: {
            margin: [0, 0, 15, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
